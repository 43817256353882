import { render, staticRenderFns } from "./OrganismBuildingLoader.vue?vue&type=template&id=41f514c2&scoped=true&"
import script from "./OrganismBuildingLoader.vue?vue&type=script&lang=js&"
export * from "./OrganismBuildingLoader.vue?vue&type=script&lang=js&"
import style0 from "./OrganismBuildingLoader.vue?vue&type=style&index=0&id=41f514c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f514c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectOrganismLoader: require('/app/components/organisms/project/OrganismLoader.vue').default})
