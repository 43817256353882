

export default {
  name: 'OrganismBuildingErrorOccurred',
  props: {
    backgroundLoaderUrl: {
      required: false,
      type: String,
      default: ""
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}
