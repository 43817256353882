
export default {
  name: 'OrganismLocation',
  props: {
    locationData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      showSidebar: false,
      queriedAddress: '',
      searchResult: {
        success: true,
        text: '',
        addressText: '',
        directionsResponse: {},
        queryResults: null
      },
      filters: {
        transportationType: this.locationData.defaultMode
      }
    }
  },
  computed: {
    searchDisabled() {
      return !this.queriedAddress
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    hasSearchResult() {
      return this.searchResult.text !== '' && this.searchResult.queryResults
    },
    hasSearchError() {
      return this.searchResult.text !== '' && !this.searchResult.success
    },
  },
  methods: {
    handleGoogleMapInitialized() {
      if (this.$refs.routesSidebar && this.locationData.activeFirstCategory) {
        const refs = this.$refs.routesSidebar.$refs;
        if (typeof refs.categoryType !== "undefined" && Array.isArray(refs.categoryType) && refs.categoryType.length > 0) {
          refs.categoryType[0].toggleSection();
        }
      }
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    showDirectionsForMarkerId(id) {
      this.$refs.map.triggerMarkerById(id)
    },
    handleSearch() {
      this.searchResult = this.$refs.map.distanceFrom(this.queriedAddress)
      this.$refs.searchInput.resetInput()
    },
    focusSearch() {
      this.$refs.searchInput.focus()
    },
    handleFiltersChange(filters){
      this.filters = {...filters}
    }
  }
}
