
import {CurrentView} from "~/store/building/-constants";

export default {
  name: 'OrganismBuildingLoader',
  props: {
    videosLoader: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    },
    contentLoaderData: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    },
    backgroundLoaderUrl: {
      required: false,
      type: String,
      default: ""
    }
  },
  computed: {
    currentSpace() {
      return this.$store?.state?.space?.space
    },
    currentSpaceVideoLoaderUrl() {
      if (!this.videosLoader || this.videosLoader.length === 0) return
      const found = this.videosLoader.find((v) => v.building_id === this.currentSpace.building_id) || null
      if (!found) return
      return found.video
    },
    loadingScreenActive() {
      return this.$store.state.building.loadingScreenActive
    },
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}
