import { render, staticRenderFns } from "./OrganismContactForm.vue?vue&type=template&id=86916688&"
import script from "./OrganismContactForm.vue?vue&type=script&lang=js&"
export * from "./OrganismContactForm.vue?vue&type=script&lang=js&"
import style0 from "./OrganismContactForm.vue?vue&type=style&index=0&id=86916688&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismContactForm.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2Fproject%2FOrganismContactForm.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,MoleculesCommonMoleculeContactForm: require('/app/components/molecules/common/MoleculeContactForm.vue').default})
