
import MoleculePin, { DefaultPinBoxHeight, DefaultPinBoxWidth } from "~/components/molecules/project/MoleculePin.vue";

export default {
  name: 'OrganismPins',
  components: {MoleculePin},
  props: {
    pinConfig: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      pinsData: [],
      expandedPin: -1,
    };
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.default();
    },
    canvasDimensions() {
      return this.clientManager.getCanvasDimensions();
    }
  },
  watch: {
    pinConfig: {
      deep: true,
      handler: function (newPinConfig) {
        this.pinsData = newPinConfig.pins;
      }
    }
  },
  mounted() {
    if (this.pinConfig) {
      this.pinsData = this.pinConfig.pins;
    }
    this.clientManager.onUpdatePinPosition = (pinState) => {
      requestAnimationFrame(() => this.handleUpdatePinPosition(pinState));
    };

    this.clientManager.onUpdatePinVisibility = (pinState) => {
      requestAnimationFrame(() => this.handleUpdatePinVisibility(pinState));
    };
  },
  methods: {
    handleUpdatePinPosition(pinsState) {
      for (let i = 0; i < this.pinsData.length; i++) {
        const pinState = pinsState?.[i];
        const componentRef = this.$refs.pinRef?.[i]?.$el;
        if (componentRef) {
          const { x, y } = i === this.expandedPin ? this.updateDivPosition(componentRef, pinState.position_2d) : pinState.position_2d;
          componentRef.style.transform = `translateX(${x}px) translateY(${y}px)`;
        }
      }
    },
    handleUpdatePinVisibility(pinsState) {
      for (let i = 0; i < this.pinsData.length; i++) {
        const pinState = pinsState?.[i];
        const componentRef = this.$refs.pinRef?.[i]?.$el;
        if (componentRef) {
          componentRef.style.display = pinState.visibility
            ? "inline-block"
            : "none";
          if (i === this.expandedPin && !pinState.visibility) {
            this.expandedPin = -1;
          }
        }
      }
    },
    handlePinExpand(index) {
      this.expandedPin = index;
      this.clientManager?.forceUpdatePinAndLabelPositions();
    },
    handlePinClose() {
      this.expandedPin = -1;
      this.clientManager?.forceUpdatePinAndLabelPositions();
    },
    updateDivPosition(div, position2d) {
      const [canvasWidth, canvasHeight] = this.canvasDimensions;

      const compound = getComputedStyle(div);
      const divMinWidth = DefaultPinBoxWidth * parseFloat(compound.fontSize) + 10;
      const divMinHeight = DefaultPinBoxHeight * parseFloat(compound.fontSize) + 10;

      const divWidth = Math.max(div.offsetWidth, divMinWidth);
      const divHeight = Math.max(div.offsetHeight, divMinHeight);

      let adjustedX = position2d.x;
      let adjustedY = position2d.y;

      if (position2d.x + divWidth / 2 > canvasWidth) {
          adjustedX = canvasWidth - divWidth / 2;
      }
      if (position2d.y + divHeight / 2 > canvasHeight) {
          adjustedY = canvasHeight - divHeight / 2;
      }
      if (position2d.x - divWidth / 2 < 0) {
          adjustedX = divWidth / 2;
      }
      if (position2d.y - divHeight / 2 < 0) {
          adjustedY = divHeight / 2;
      }

      return {x: adjustedX, y: adjustedY}
    }
  }
}
