
export default {
  name: 'OrganismTechnicalSpecs',
  props: {
    bulletTechnicalData: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    imageSpecs: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeBullet: 'item-0',
      activeCategory: 'category-0',
      isActive: false,
      isInView: false,
      imageLoaded: false
    }
  },
  computed: {
    resourceUrl() {
      if (this.imageSpecs.includes('://')) {
        return this.imageSpecs
      }
      return `${this.$store.getters.cdnBase}/${this.imageSpecs}`
    },
    specsByCategory() {
      const categories = []
      this.bulletTechnicalData.forEach((i) => {
        const item = { ...i }
        let category = categories.find((e) => e.slug === item.category.slug)
        if (!category) {
          category = { ...item.category, items: [] }
          categories.push(category)
        }
        delete item.category
        category.items.push(item)
      })
      return categories
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    handleBulletChange(id, category) {
      this.activeBullet = id
      this.$nextTick(() => {
        if (category) {
          this.handleCategoryChange(`id-${category.slug}`)
          setTimeout(() => {
            this.$refs[`${id}`][0].$el?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest'
            })
          }, 200)
        }
      })
    },
    handleCategoryChange(id) {
      this.activeCategory = id
      this.$emit('category-change', id)
    },
    handleLazyLoad() {
      this.isInView = true
      const imageElement = new Image()
      if (imageElement) {
        imageElement.addEventListener('load', () => {
          this.imageLoaded = true
        })
        imageElement.addEventListener('error', () => console.log('error'))
        imageElement.src = this.resourceUrl
      }
    }
  }
}
